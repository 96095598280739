import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Stack, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	"& .MuiDialogContent-root": {
		padding: theme.spacing(2),
	},
	"& .MuiDialogActions-root": {
		padding: theme.spacing(1),
	},
}));

export default function TenantDelete({ details }) {
	const [open, setOpen] = React.useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};

	return (
		<React.Fragment>
			<Button sx={{ color: "red", padding: "10px" }} onClick={handleClickOpen}>
				<DeleteIcon sx={{ fontSize: "16px", marginRight: "5px" }} /> DELETE TENANT
			</Button>
			<BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
				<DialogTitle sx={{ m: 0, p: 2, fontSize: "20px" }} id="customized-dialog-title">
					Delete Tenant?
				</DialogTitle>
				<IconButton
					aria-label="close"
					onClick={handleClose}
					sx={{
						position: "absolute",
						right: 8,
						top: 8,
						color: (theme) => theme.palette.grey[500],
					}}
				>
					<CloseIcon />
				</IconButton>
				<DialogContent sx={{ width: { xs: "256px", sm: "500px" } }} dividers>
					{/* <Stack spacing={3}>
						<Box sx={{ display: "flex", alignItems: "center", fontSize: "16px" }}>
							<strong>&nbsp;Ticket:&nbsp;</strong>
							<Typography variant="h5">{details.title}</Typography>
						</Box>
						<Box sx={{ display: "flex", alignItems: "center", fontSize: "16px" }}>
							<PersonIcon />
							<strong>&nbsp;Logged by:&nbsp;</strong>
							<Typography variant="h5">
								{details.related_tenant.first_name} {details.related_tenant.last_name}
							</Typography>
						</Box>
						<Box sx={{ display: "flex", alignItems: "center", fontSize: "16px" }}>
							<PhoneIcon />
							<strong>&nbsp;Tenant Contact:&nbsp;</strong>
							<Typography variant="h5">0{details.related_tenant.phone_number}</Typography>
						</Box>
						<Box sx={{ display: "flex", alignItems: "center", fontSize: "16px" }}>
							<EmailIcon />
							<strong>&nbsp;Tenant Email:&nbsp;</strong>
							<Typography variant="h5">{details.related_tenant.email}</Typography>
						</Box>
						<Box sx={{ display: "flex", alignItems: "center", fontSize: "16px" }}>
							<ArticleIcon />
							<strong>&nbsp;Description:&nbsp;</strong>
							<Typography variant="h5">{details.description}</Typography>
						</Box>
					</Stack> */}
					Are you sure you want to delete this Tenant?
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Cancel</Button>
					<Button className="add-button" vairant="contained" onClick={handleClose} autoFocus>
						Delete
					</Button>
				</DialogActions>
			</BootstrapDialog>
		</React.Fragment>
	);
}
