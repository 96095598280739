import * as React from "react";
import { Grid, Box, Paper, Button } from "@mui/material";
import PaymentsTable from "components/Tables/PaymentsTable";
import TicketsTable from "components/Tables/TicketsTable";
import Sidebar from "scenes/global/Sidebar";
import Navbar from "scenes/navbar";
import BasicCard from "components/Card";
import DummyCard from "components/DummyCard";
import DummyTable from "components/Tables/DummyTable";
import DoughnutChart from "components/Graphs/DoughnutChart";
import { useGetDashboardData } from "services/hooks/rentals";
import Skeleton from "@mui/material/Skeleton";
import { formatDate } from "utilities/date";
import { FaHouseChimneyUser } from "react-icons/fa6";
import { FaRegFolderOpen } from "react-icons/fa";
import { FaMoneyBills } from "react-icons/fa6";
import { MdPeopleAlt } from "react-icons/md";
import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import FormDialog from "components/modal";
import RentalsForm from "components/Forms/RentalsForm";
import TopSnack from "components/TopSnack";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setRefreshCount } from "state";
import { useRentals } from "../../services/hooks/rentals";
import axios from "axios";
import { baseURL } from "../../services/API";

export default function Dashboard() {
	const [open, setOpen] = React.useState(false);
	const { dashboardData, isLoadingDashboardData, errorLoadingDashboardData } = useGetDashboardData();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const landlordId = useSelector((state) => state.landlord);
	const { rentals, isLoadingRentals } = useRentals();
	const [rental, setRental] = React.useState("");
	const [modal, setModal] = React.useState("");
	const [details, setDetails] = React.useState("");
	const [rentalName, setRentalName] = React.useState("");
	const [rentalType, setRentalType] = React.useState("");
	const [rentalLocation, setRentalLocation] = React.useState("");
	const [rentalCity, setRentalCity] = React.useState("");
	const [security, setSecurity] = React.useState(false);
	const [activeRental, setActiveRental] = React.useState("");
	const [anchorEl, setAnchorEl] = React.useState(null);
	const openPopper = Boolean(anchorEl);
	const popperId = openPopper ? "simple-popover" : undefined;
	const [openSnack, setOpenSnack] = React.useState({
		open: false,
		vertical: "top",
		horizontal: "center",
	});
	const [severity, setSeverity] = React.useState("");
	const [message, setMessage] = React.useState("");

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

		const handleRentalNameChange = (event) => {
			setRentalName(event.target.value);
		};

		const handleChangeRentalType = (event) => {
			setRentalType(event.target.value);
		};

		const handleRentalLocationChange = (event) => {
			setRentalLocation(event.target.value);
		};

		const handleRentalCityChange = (event) => {
			setRentalCity(event.target.value);
		};

		const handleSecurityCheck = (event) => {
			setSecurity(event.target.checked);
		};

		const handleClickPopOver = (event, row) => {
			setAnchorEl(event.currentTarget);
			setActiveRental(row);
		};

		const handleClosePopOver = () => {
			setAnchorEl(null);
		};

		const handleCloseSnack = (event, reason) => {
			if (reason === "clickaway") {
				return;
			}
			setOpenSnack({ ...openSnack, open: false });
		};

		const handleModalView = (modalView) => {
			setModal(modalView);
		};

		const handleRentalFormSubmit = async () => {
			try {
				await axios.post(`${baseURL}/landlords/rentals`, {
					related_landlord: landlordId,
					rental_name: rentalName,
					rental_type: rentalType,
					location: rentalLocation,
				});
				// setSuccess(true);
				// setLoading(false);
				setOpenSnack({ ...openSnack, open: true });
				setSeverity("success");
				setMessage("Property Added Successfully");
				setOpen(false);
				dispatch(setRefreshCount());
			} catch (err) {
				setOpenSnack({ ...openSnack, open: true });
				setSeverity("error");
				setMessage("Property Addition failed, Please check property details");
			}
		};

	return (
		<Box m="20px">
			<Grid container>
				{isLoadingDashboardData ? (
					<Grid item xs={12}>
						<h1>Dashboard</h1>
						<Grid container spacing={4}>
							<Grid item xs={3}>
								<DummyCard />
							</Grid>
							<Grid item xs={3}>
								<DummyCard />
							</Grid>
							<Grid item xs={3}>
								<DummyCard />
							</Grid>
							<Grid item xs={3}>
								<DummyCard />
							</Grid>
						</Grid>
						<Grid container spacing={2}>
							<Grid container item xs={7} spacing={2}>
								<Grid item xs={12} sx={{ marginTop: 1 }}>
									<h2>Rent Due Tenants</h2>
									<DummyTable />
								</Grid>

								<Grid item xs={12} sx={{ marginTop: 1 }}>
									<h2>Tickets</h2>
									<DummyTable />
								</Grid>
							</Grid>
							<Grid
								container
								item
								xs={5}
								spacing={2}
								sx={{
									marginTop: 4,
									padding: 4,
								}}
							>
								<Paper
									sx={{
										margin: 3,
										padding: 4,
										width: "100%",
									}}
								>
									<h2>Rent Payments</h2>
									<Skeleton></Skeleton>
								</Paper>
							</Grid>
						</Grid>
					</Grid>
				) : (
					<Grid item xs={12}>
						<Box sx={{ marginBottom: "15px", display: { xs: "block", sm: "flex", md: "flex" }, alignItems: "center", justifyContent: "space-between" }}>
							<h1>Dashboard</h1>
							<Button
								sx={{ marginBottom: { xs: "20px", sm: 0 } }}
								onClick={handleClickOpen}
								className="add-button"
								variant="contained"
								endIcon={<AddIcon />}
							>
								Add Property
							</Button>
						</Box>
						<Grid container spacing={3}>
							<Grid item xs={12} md={5}>
								<Grid container spacing={2}>
									<Grid item xs={12} md={6}>
										<BasicCard
											link="/properties"
											name={"PROPERTIES"}
											value={dashboardData.properties}
											color="#FCB200"
											bgcolor="#fff9eb"
											icon={<FaHouseChimneyUser />}
										/>
									</Grid>
									<Grid item xs={12} md={6}>
										<BasicCard link="/tickets" name={"TICKETS"} value={dashboardData.tickets} color="#6A8BE8" bgcolor="#ebefff" icon={<FaRegFolderOpen />} />
									</Grid>
									<Grid item xs={12} md={6}>
										<BasicCard link="/payments" name={"TOTAL EXPECTED (MINUS 7%)"} value={dashboardData.total} color="#FF5100" bgcolor="#ffebff" icon={<FaMoneyBills />} />
									</Grid>
									<Grid item xs={12} md={6}>
										<BasicCard link="/tenants" name={"TENANTS"} value={dashboardData.tenants} color="#A05ACE" bgcolor="#ffebff" icon={<MdPeopleAlt />} />
									</Grid>
								</Grid>
							</Grid>
							<Grid item xs={12} md={7}>
								<span
									style={{
										display: "flex",
										alignItems: "center",
										justifyContent: "space-between",
									}}
								>
									<h3 style={{ margin: "0 0 8px 0" }}>Tickets</h3>
									<Link to="/tickets">
										<h4 style={{ color: "#2094FF", margin: "0 0 8px 0" }}>Show all</h4>
									</Link>
								</span>

								<TicketsTable rows={dashboardData.tickets_list} formatDate={formatDate} />
							</Grid>
						</Grid>
						<Grid container spacing={3} sx={{ marginTop: "15px" }}>
							<Grid item xs={12} md={5}>
								<h3 style={{ margin: "0 0 8px 0" }}>Rent Payments</h3>
								<Box sx={{ backgroundColor: "#fff", padding: "30px" }}>
									<DoughnutChart />
								</Box>
							</Grid>
							<Grid item xs={12} md={7}>
								<span
									style={{
										display: "flex",
										alignItems: "center",
										justifyContent: "space-between",
									}}
								>
									<h3 style={{ margin: "0 0 8px 0" }}>Rent Due Tenants</h3>
									<Link to="/payments">
										<h4 style={{ color: "#2094FF", margin: "0 0 8px 0" }}>Show all</h4>
									</Link>
								</span>
								<PaymentsTable rows={dashboardData.tenants_list} />
							</Grid>
						</Grid>
					</Grid>
				)}
				<FormDialog
					open={open}
					handleClose={handleClose}
					enableActions={true}
					content={
						<RentalsForm
							rentalType={rentalType}
							rentalCity={rentalCity}
							handleRentalNameChange={handleRentalNameChange}
							handleChangeRentalType={handleChangeRentalType}
							handleRentalLocationChange={handleRentalLocationChange}
							handleRentalCityChange={handleRentalCityChange}
							handleSecurityCheck={handleSecurityCheck}
							security={security}
						/>
					}
					title={"Add all Property Details"}
					handleSubmit={handleRentalFormSubmit}
					buttonText="submit"
				/>
				<TopSnack
					vertical={openSnack.vertical}
					horizontal={openSnack.horizontal}
					open={openSnack.open}
					handleCloseSnack={handleCloseSnack}
					message={message}
					severity={severity}
				/>
			</Grid>
		</Box>
	);
}
