import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

export default function FormDialog(props) {
	return (
		<div>
			<Dialog open={props.open} onClose={props.handleClose}>
				<DialogTitle sx={{ m: 0, p: "1 3", fontSize: 22, fontWeight: 700, borderBottom: "solid 1px #e0e0e0" }}>
					{props.title}{" "}
					<IconButton
						aria-label="close"
						onClick={props.handleClose}
						sx={{
							position: "absolute",
							right: 8,
							top: 8,
							color: (theme) => theme.palette.grey[500],
						}}
					>
						<CloseIcon />
					</IconButton>
				</DialogTitle>
				<DialogContent sx={{ marginTop: 2, p: 3, minWidth: { xs: "256px", sm: "500px" } }}>
					<DialogContentText>{props.text}</DialogContentText>
					{props.content}
				</DialogContent>
				{props.enableActions ? (
					<DialogActions sx={{ m: 0, p: "1rem", borderTop: "solid 1px #e0e0e0" }}>
						<Button variant="text"  onClick={props.handleClose}>
							Cancel
						</Button>
						<Button className="add-button" onClick={props.handleSubmit}>
							{props.buttonText}
						</Button>
					</DialogActions>
				) : null}
			</Dialog>
		</div>
	);
}
