import { Box } from "@mui/material";
import { styled } from "@mui/system";

export const FlexCenter = styled(Box)({
	display: "flex",
	alignItems: "center",
});

export const FlexStart = styled(Box)({
	display: "flex",
	alignItems: "flex-start",
});

export const FlexEnd = styled(Box)({
	display: "flex",
	alignItems: "flex-end",
});

export const FlexJustifyStart = styled(Box)({
	display: "flex",
	justifyContent: "start",
	alignItems: "center",
});

export const FlexBetween = styled(Box)({
	display: "flex",
	justifyContent: "space-between",
	alignItems: "center",
});

export const FlexColCenter = styled(Box)({
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	flexDirection: "column",
});

export const FlexRowCenter = styled(Box)({
	marginTop: "10px",
	display: "flex",
	justifyContent: "space-between",
	alignItems: "center",
	flexDirection: "row",
});