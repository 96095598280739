import * as React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import TenantUpdateForm from 'components/Forms/TenantUpdateForm';
import TenantUpdateModal from 'components/ModalContent/UpdateItem/TenantUpdateModal';
import TenantMessageModal from 'components/ModalContent/TenantMessageModal';
import TenantDelete from 'components/ModalContent/DeleteItem/TenantDelete';


export default function TenantsPopover({
	popperId,
	openPopper,
	anchorEl,
	handleClickOpen, 
	handleClosePopOver,
	details,
	handleClickOpenTenantDetails,
	handleModalView,
}) {
	const handleAction = (modalview) => {
		handleModalView(modalview);
		handleClickOpen();
	};
	return (
		<div>
			<Popover
				id={popperId}
				open={openPopper}
				anchorEl={anchorEl}
				onClose={handleClosePopOver}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
			>
				<Stack
					sx={{
						padding: "10px",
						alignContent: "left",
					}}
				>
					{/* <Button sx={{ color: "black", padding: "10px" }} onClick={() => handleAction("edit")}>
						Edit Tenant
					</Button> */}
					<Divider />
					<Button sx={{ color: "black", padding: "10px" }} onClick={() => handleAction("message")}>
						Send Message
					</Button>
					<Divider />
					<Button sx={{ color: "red", padding: "10px" }} onClick={() => handleAction("delete")}>
						Delete Tenant
					</Button>
					<Divider />
				</Stack>
			</Popover>
		</div>
	);
}